import ron from '../../assets/Ron-Website.jpg';
import jerome from '../../assets/Jerome-Website.jpg';
import simone from '../../assets/Simone-Website.jpg';

export const cardDataOne = {
    fullName: "Anthony Simone",
    title: "Director of Operations",
    description: "Anthony serves as the Director of Operations at Merch For All, bringing years of experience in project management, workflow optimization, and technical solutions. Since joining in 2021, they have been instrumental in streamlining operations, overseeing large-scale merchandise projects, and ensuring a seamless experience for clients from concept to fulfillment.",
    description2: "With a strong background in web development, strategic planning, and budget management, Anthony focuses on delivering efficient, scalable solutions that drive growth and enhance the client experience. Whether managing product launches, optimizing logistics, or refining internal processes, their commitment to precision and innovation helps brands and creators bring their merchandise visions to life effortlessly.",
    description3: "",
    imgLink: simone
}

export const cardDataTwo = {
	fullName: "Ron Kieftenbeld",
	title: "Managing owner",
	description: "Ron entered the digital media space in October of 2016, working closely on projects with Jerome Aceti and Parker Coppins. Throughout their partnership, they have looked to tackle and continuously improve online influencer merchandising. Understanding the dynamic nature of the industry, they look to stay up to date with current trends, and expand our offerings for both our clients and end consumers alike.",
	description2: "Ron is former data analyst at A.T Kearney based out of New York City, working specifically with the Procurement and Analytic Solutions group, supporting a variety of blue chip clients to help improve and optimize their operations. A proud graduate of the College of the Holy Cross in Worcester, MA where he completed a double major in Economics and Asian Studies, Ron takes a keen interest in global economic and societal issues.",
	description3: "In 2013, 2014 and 2015, Ron completed internships at A.T. Kearney, a leading global management consulting firm, where he worked in their Procurement and Analytic Solutions group. The first two years he developed and delivered research projects that support the firm's future go-to-market strategy and client engagement approach. During his third year, he was engaged in a client assignment.",
	imgLink: ron
}

export const cardDataThree = {
	fullName: "Jerome Aceti",
	title: "Managing Owner",
	description: "Jerome Aceti is one of the founders of Merch For All, LLC. He began his adventure into the merchandise industry back in late 2016. Jerome is best known as a YouTuber with over five million subscribers across his channels and over one billion video views. He is no stranger to the entertainment space with past content distribution deals with YouTube, Amazon, Sony Vue, Verizon Go90, and more.",
	description2: "In his spare time he has also written a few children's novels that are available in retail locations. His business experiences began in early 2012 and have ranged throughout the media, gaming, entertainment and music industries in both investment and management forms.",
	description3: "",
	imgLink: jerome
}


// export const cardDataFive = {
//     fullName: "Placeholder",
//     title: "Placeholder",
//     description: "Placeholder",
//     description2: "Placeholder",
//     description3: "Placeholder",
//     imgLink: test
// }